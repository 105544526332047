<template>
  <div class="">
    <div class="hero-image-cn">
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d288.01651679776097!2d100.48859255435268!3d13.774476990997913!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e299790d70e105%3A0x50057fe7b742c718!2sBella%20Jewelry%20(Factory)%20Co.%2C%20Ltd.!5e0!3m2!1sth!2sth!4v1678336185179!5m2!1sth!2sth" width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
  <div id="root-app" style="display:none">
    <google-map
      :config="mapConfig"
      apikey="AIzaSyDT2fmd4UMgihaYwhAB9KZ9u5v-mgZt8dY">
      <GoogleMapMarkers :markers="mapMarkers"/>
    </google-map>
  </div>

      <img class="style-img" src="@/assets/image-contact/image-map2.png"  style="display:none" />
      <img
        class="style-img-mini"
        src="@/assets/image-contact/image-minimap3.png"
      />
    </div>
    <div class="container my-5">
      <div class="row ">
        <div class="col-12 col-md-12">
          <div class="text-small-ct ">
            <span>
              {{ $t("message.content84") }}
            </span>
          </div>
          <div class="text-header-ct">
            <p>
              {{ $t("message.content85") }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="container pb-5">
      <div class="row justify-content-md-center pb-4">
        <div class="cols-12 col-md-6 pd-left-five-ct">
          <div class="my-2">
            <div class="row">
              <div class="col-3 col-md-3 d-ct">
                <img
                  class="image-size-ct"
                  src="@/assets/image-contact/Icon_Location.png"
                />
              </div>
              <div class="col-9 col-md-9 mt-3">
                <div>
                  <div class="col-12">
                    <span class="text-title-ct">{{
                      $t("message.content86")
                    }}</span>
                  </div>
                  <div class="col-12">
                    <span class="text-title-sub-ct">
                      {{ $t("message.content87") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-4">
            <div class="row">
              <div class="col-3 col-md-3 d-ct">
                <img
                  class="image-size-ct"
                  src="@/assets/image-contact/Icon_Email.png"
                />
              </div>
              <div class="col-9 col-md-9 mt-3 ">
                <div>
                  <div class="col-12">
                    <span class="text-title-ct ">{{
                      $t("message.content88")
                    }}</span>
                  </div>
                  <div class="col-12">
                    <span class="text-title-sub-ct">
                      {{ $t("message.content89") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="my-5">
            <div class="row">
              <div class="col-3 col-md-3 d-ct">
                <img
                  class="image-size-ct"
                  src="@/assets/image-contact/Icon_Phone.png"
                />
              </div>
              <div class="col-9 col-md-9 mt-3 ">
                <div>
                  <div class="col-12">
                    <span class="text-title-ct">{{
                      $t("message.content90")
                    }}</span>
                  </div>
                  <div class="col-12">
                    <span class="text-title-sub-ct">
                      {{ $t("message.content91") }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cols-12 col-md-6 ">
          <form>
            <div class="  mb-4">
              <div class="text-style mb-2">
                <label> {{ $t("message.content92") }}:</label>
              </div>
              <div class="text-input">
                <input
                  type="text"
                  name="name"
                  id="name"
                  class="form-control input-style "
                  :placeholder="$t('message.content93')"
                />
              </div>
            </div>

            <div class=" mb-4">
              <div class="text-style mb-2">
                <label>{{ $t("message.content94") }}:</label>
              </div>
              <div class="text-input">
                <input
                  type="text"
                  name="email"
                  id="email"
                  class="form-control input-style "
                  :placeholder="$t('message.content95')"
                />
              </div>
            </div>
            <div class=" mb-4 ">
              <div class="text-style mb-2">
                <label>{{ $t("message.content96") }}:</label>
              </div>
              <div class=" text-input">
                <input
                  type="text"
                  name="number"
                  id="number"
                  maxlength="10"
                  @input="filterInput"
                  class="form-control  input-style"
                  :placeholder="$t('message.content97')"
                />
              </div>
            </div>
            <div class=" mb-4">
              <div class="text-style mb-2">
                <label>{{ $t("message.content98") }}:</label>
              </div>
              <div class=" text-input ">
                <textarea
                  type="text"
                  name="message"
                  id="message"
                  class="form-control input-style-big pt-4"
                  rows="3"
                  :placeholder="$t('message.content99')"
                ></textarea>
              </div>
            </div>
            <div class="d-flex justify-content-start ">
              <button
                class="custom-btn-ct text-btn-ct"
                type="submit"
                value="send"
                @click="sendMail()"
              >
                {{ $t("message.content100") }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>


<style>
html,
body {
  margin: 0;
  padding: 0;
}
</style>
<script>
import GoogleMap from '@/components/googlemap/GoogleMap.vue';
import GoogleMapMarkers from '@/components/googlemap/GoogleMapMarkers.vue';

export default {
  components: {
    GoogleMap,
    GoogleMapMarkers
  },
  methods: {
    sendMail() {
      const name = document.getElementById("name").value;
      const number = document.getElementById("number").value;
      const email = document.getElementById("email").value;
      const message = document.getElementById("message").value;
      window.open(
        `mailto:viruch@mondial.co.th?&body=${"Fullname : " +
          name +
          "%0A" +
          "Email Address : " +
          email +
          "%0A" +
          "Phone Number : " +
          number +
          "%0A" +
          "Message : " +
          message}
        `
      );
    },
    filterInput(e) {
      e.target.value = e.target.value.replace(/[^0-9]+/g, "");
    },
    action_button() {
      let activeBtn = "btn6";
      localStorage.setItem("activeBtn_set", JSON.stringify(activeBtn));
    },
  },
  mounted() {
    this.action_button();
  },
  data() {
    return {
      mapConfig: {
        zoom: 22,
        center: {
          lat: 13.77447949080616,
          lng: 100.48868564275311,
        },
      },
      mapMarkers: [
        {
          name: 'Bella Jewelry',
          lat: 13.77447949080616,
          lng: 100.48868564275311,
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/home";
@import "@/assets/scss/contact";
@import "@/assets/scss/main";
</style>
